<template>
  <b-card no-body title="Welcome Message">
    <div>
      <b-button-toolbar justify class="toolbar-top-margin">
        <b-form-group label-align-sm="left" label-size="md" label-for="filterInput" class="mb-0 search-bar-margin">
          <b-input-group size="sm">
            <b-form-input id="filterInput" v-model="filterBy" type="search" placeholder="Type to Search" debounce="900" :disabled="getLoading" />
          </b-input-group>
        </b-form-group>
        <b-button-group class="mr-2 mb-1">
          <b-button
            v-b-tooltip.hover
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-primary"
            class="btn-icon"
            title="Add new item"
            @click="addNewMessage"
            :disabled="getLoading"
          >
            <feather-icon icon="FilePlusIcon" />
          </b-button>

          <b-button v-b-tooltip.hover v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-primary" title="Refresh data" class="btn-icon" @click="loadInitial">
            <b-spinner v-if="getLoading" small />
            <feather-icon icon="RefreshCwIcon" />
          </b-button>
        </b-button-group>
      </b-button-toolbar>
    </div>
    <b-overlay :show="getLoading" rounded="sm" opacity="0.8">
      <b-table responsive :fields="fields" :items="getMessageList" :current-page="currentPage">
        <template #cell(splashMsgType)="data">
          <b-link v-model="data.detailsShowing" @click="data.toggleDetails">
            {{ data.value }}
          </b-link>
        </template>

        <template #cell(priority)="data">
          <b-form-checkbox :checked="data.value" class="custom-control-success" name="active-button" @change="toggleActiveSync($event, data.item, 'PRIORITY')" switch />
        </template>

        <template #cell(disabled)="data">
          <b-form-checkbox :checked="data.value" class="custom-control-success" name="active-button" @change="toggleActiveSync($event, data.item, 'DISABLED')" switch />
        </template>

        <template #cell(created)="data">
          {{ showDateInFormat(data.value) }}
        </template>

        <!-- full detail on click -->
        <template #row-details="row">
          <template v-if="editingItem == row.item.id">
            <b-container class="stuff">
              <b-form>
                <b-row>
                  <b-col cols="8">
                    <b-form-group id="input-group-1" label-for="input-short-name" label-cols="2" label-align="right">
                      <template #label>
                        <b>Short name:</b>
                      </template>
                      <b-form-input
                        id="input-short-name"
                        maxlength="500"
                        v-model="form.type"
                        type="url"
                        placeholder="Enter short name"
                        required
                        :disabled="!row.item.isNew"
                      ></b-form-input>
                    </b-form-group>

                    <b-form-group label="Organization" label-cols="2" label-for="orgForItem" label-align="right">
                      <template #label>
                        <b>Organization:</b>
                      </template>
                      <b-form-select id="orgForItem" value-field="OrganizationId" text-field="name" v-model="form.orgId" :options="getAllOrgs">
                        <template #first>
                          <b-form-select-option :value="null" disabled>-- Please select an Organization --</b-form-select-option>
                        </template>
                      </b-form-select>
                    </b-form-group>
                    <b-form-group id="input-group-2" label-for="input-tcversion" label-cols="2" label-align="right">
                      <template #label>
                        <b>Activate:</b>
                      </template>
                      <b-form-datepicker id="example-datepicker" v-model="form.activateOn" class="mb-2"></b-form-datepicker>
                    </b-form-group>

                    <br />
                    <b-form-group id="input-toggles" label=" " label-cols="2" label-align="right">
                      <b-form-checkbox v-model="form.disabled">Disabled?</b-form-checkbox>
                      <b-form-checkbox v-model="form.priority">Priority?</b-form-checkbox>
                    </b-form-group>
                    <br />
                  </b-col>
                </b-row>
                <b-row>
                  <b-col><b-form-textarea id="textarea" v-model="form.splashMessage" rows="12"></b-form-textarea></b-col>
                  <b-col>
                    <b-card bg-variant="light-secondary" border-variant="secondary" tag="article" style="max-width: 80%" class="mb-2">
                      <b-card-text>
                        <div v-html="compiledMarkdown(form.splashMessage)" />
                      </b-card-text>
                    </b-card>
                  </b-col>
                </b-row>
                <br />
                <b-button @click="saveNewWelcome" variant="primary">Submit</b-button>
                <b-button @click="cancelSave(row.item)" variant="danger">Cancel</b-button>
              </b-form>
            </b-container>
          </template>
          <template v-else>
            <b-button id="edit_blog_news_button" class="btn-icon success" v-b-tooltip.hover variant="gradient-primary" title="Edit Message" @click="editItem(row.item)">
              Edit
            </b-button>
            <br />
            <b-card bg-variant="light-secondary" border-variant="secondary" tag="article" style="max-width: 80%" class="mb-2">
              <b-card-text>
                <div v-html="compiledMarkdown(row.item.messageTxt)" />
              </b-card-text>
            </b-card>
          </template>
        </template>
      </b-table>

      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
        <!-- page length -->
        <div class="col-1">
          <b-form-select id="perPageSelect" :value="getPerPage" size="sm" inline :options="pageOptions" @change="perPageChanged" />
          <p class="text-nowrap mb-md-0 mr-1"><b>Total rows:</b> {{ getTotalRows }}</p>
        </div>

        <!-- pagination -->
        <div>
          <b-pagination
            :value="currentPage"
            :total-rows="getTotalRows"
            :per-page="getPerPage"
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
            limit="1"
            hide-ellipsis
            hide-goto-end-buttons
            @change="onPageChanged"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </div>
      </b-card-body>
    </b-overlay>
  </b-card>
</template>

<script>
import {
  fetchWelcomeMessages,
  saveNewWelcomeMsg,
  getMessageList,
  filterBy,
  setPerPage,
  getLoading,
  getTotalRows,
  getPerPage,
  toggleSplashActive,
  addNewWelcomeItem
} from "@/views/listoconfig/listoRequests";
import { loadAllOrgs, getAllOrgs } from "@/views/listoconfig/blogRequests";
import { DEFAULT_PAGE_OPTIONS } from "@/listo/defaults";
import { onMounted, ref, watch } from "@vue/composition-api";
import Swal from "sweetalert2";
import Ripple from "vue-ripple-directive";
import moment from "moment";
import marked from "marked";

export default {
  name: "WelcomeMessage",
  components: {
    Ripple
  },
  directives: {
    Ripple
  },
  setup(_, { root }) {
    const msgInput = ref("");
    const editingItem = ref(-1);
    const currentPage = ref(1);
    const pageOptions = DEFAULT_PAGE_OPTIONS;
    const fields = [
      {
        key: "splashMsgType",
        label: "Type"
      },
      "priority",
      "disabled",
      "activateOn",
      "created"
    ];

    const DEFAULT_FORM = {
      type: "",
      disabled: false,
      priority: false,
      activateOn: new Date(),
      orgObject: null,
      defaultOrg: null,
      active: false,
      splashMessage: "",
      orgId: "",
      created: new Date(),
      splashMsgType: "NEW",
      splashMessageUid: ""
    };
    const form = ref(DEFAULT_FORM);
    let nextId = 99999;

    const toggleActiveSync = async (newVal, item, type) => {
      let req = {};
      req.forSplashUid = item.splashMessageUid;
      req.updateBoolean = newVal;
      req.updateType = type;

      try {
        await toggleSplashActive(req);
      } catch (err) {
        let respMsg = "";
        if (err.response) {
          respMsg = err.response.data && err.response.data.message ? err.response.data.message : err.response.data.error ? err.response.data.error : "Server error";
        }
        respMsg = respMsg === "" ? "Unknown Error" : respMsg;
        root.$bvToast.toast(`Message: ${respMsg}`, {
          title: `Updating active state for ${item.type} Failed`,
          toaster: "b-toaster-bottom-center",
          variant: "danger",
          autoHideDelay: 5000
        });
        await loadInitial();
      }
    };

    const showDateInFormat = (date) => moment(date).format("YYYY-MM-DD hh:mm:ss a");

    watch(filterBy, (newValue, oldValue) => {
      loadInitial();
    });

    const compiledMarkdown = (data) => {
      if (data && data.length > 0) {
        return marked(data);
      }
      return marked("");
    };

    const onInputUpdated = (value) => {
      if (value.length > 9000) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Text is too long! ",
          customClass: {
            confirmButton: "btn btn-primary"
          },
          buttonsStyling: false
        });

        return;
      }
      msgInput.value = value;
    };

    const loadInitial = async () => fetchWelcomeMessages({ prevPage: false, nextPage: false, reset: true });

    const perPageChanged = async (val) => {
      setPerPage(val);
      await initalLoadOrgs();
    };

    const onPageChanged = async (val) => {
      if (val == 1) {
        currentPage.value = val;
        await onLoadAllMetrcSyncStatus({ prevPage: false, nextPage: false, reset: true });
        return;
      }
      if (currentPage.value > val) {
        await onLoadAllMetrcSyncStatus({ prevPage: true, nextPage: false });
        currentPage.value = val;
        return;
      }
      await onLoadAllMetrcSyncStatus({ prevPage: false, nextPage: true });
      currentPage.value = val;
    };

    const addNewMessage = () => {
      let newData = DEFAULT_FORM;
      newData.id = nextId++;
      newData.isNew = true;
      newData.created = new Date();
      newData.splashMsgType = "NEW";
      newData.splashMessage = "Add text";
      newData._showDetails = true;
      console.log("all", getAllOrgs);
      newData.orgObject = getAllOrgs.value ? getAllOrgs.value[0].OrganizationId : null;

      addNewWelcomeItem(newData);
    };

    const editItem = (item) => {
      console.log("Editing", item);
      form.value.isEdit = true;
      form.value.id = item.id;
      form.value.splashMessageUid = item.splashMessageUid;
      form.value.splashMessage = item.messageTxt;
      form.value.activateOn = item.activateOn;
      form.value.priority = item.priority;
      form.value.type = item.splashMsgType;
      form.value.disabled = item.disabled;
      form.value.splashMsgType = item.splashMsgType;
      form.value.orgId = item.organizationUid;
      form.value.isNew = false;
      editingItem.value = item.id;
      form.value.orgObject = getAllOrgs.value ? getAllOrgs.value.find((o) => o.OrganizationId === item.organizationUid) : null;
      console.log("Form", form);
    };

    onMounted(() => {
      filterBy.value = "";
      loadAllOrgs();
    });

    const saveNewWelcome = async () => {
      let pd = {};
      if (form.isEdit) {
        pd.splashMessageUid = form.splashMessageUid;
      }
      pd.messageTxt = form.value.text;
      pd.activateOn = form.value.activateOn;
      pd.priority = form.value.priority;
      pd.splashMsgType = form.value.splashMsgType;
      pd.disabled = form.value.disabled;
      pd.organizationUid = form.value.organizationUid;
      if (form.value.orgObject) {
        pd.organizationUid = form.value.orgObject.OrganizationId;
      }
      console.log("Saving with ", pd);
      try {
        await saveNewWelcomeMsg(pd);
      } catch (err) {
        console.log("error", err.response);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong! ",
          customClass: {
            confirmButton: "btn btn-primary"
          },
          buttonsStyling: false
        });
      } finally {
        form.value.text = "";
        form.value.activateOn = new Date();
        form.value.splashMsgType = "NEW";
        form.value.disabled = false;
        form.value.priority = false;
        form.value.organizationUid = "";
        form.value.orgObject = null;
        form.value.isNew = false;
        form.value.isEdit = false;
        editingItem.value = -1;
      }
    };

    return {
      addNewMessage,
      loadInitial,
      onInputUpdated,
      compiledMarkdown,
      getLoading,
      saveNewWelcome,
      msgInput,
      marked,
      getMessageList,
      filterBy,
      setPerPage,
      getLoading,
      getTotalRows,
      getPerPage,
      currentPage,
      pageOptions,
      fields,
      perPageChanged,
      onPageChanged,
      showDateInFormat,
      toggleActiveSync,
      form,
      getAllOrgs,
      editItem,
      editingItem
    };
  }
};
</script>
