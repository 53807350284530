import { ref, computed } from "@vue/composition-api";

import { requestUpdateWelcomeMessage, requestLoadWelcomeMessage, requestFlagWelcomeMessage } from "@/service/ListoService";

const msgList = ref([]);
export const filterBy = ref(null);
const perPage = ref(10);
const loading = ref(false);
let lastPageItem = 0;
let firstPageItem = 0;
const totalRows = ref(0);

export const setPerPage = (val) => (perPage.value = val);
export const getPerPage = computed(() => perPage.value);
export const getLoading = computed(() => loading.value);
export const getTotalRows = computed(() => totalRows.value);
export const getMessageList = computed(() => msgList.value);

export const fetchWelcomeMessages = async ({ prevPage, nextPage, reset }) => {
  let useLast = 0;
  if (!reset) {
    if (nextPage) {
      useLast = lastPageItem;
    }
    if (prevPage) {
      useLast = firstPageItem - 1;
    }
  }
  const reqData = {
    lastItem: useLast,
    perPage: perPage.value,
    filter: {
      search: filterBy.value || null
    }
  };
  loading.value = true;
  try {
    const {
      data: { messages, pagination }
    } = await requestLoadWelcomeMessage(reqData);

    if (messages) {
      msgList.value.splice(0, msgList.value.length);
      msgList.value.push(...messages);
    } else {
      msgList.value.splice(0, msgList.value.length);
    }
    lastPageItem = pagination.lastItem;
    firstPageItem = pagination.firstItem;
    totalRows.value = pagination.totalRows ? pagination.totalRows : 10;
  } finally {
    loading.value = false;
  }
};

export const addNewWelcomeItem = async (newdata) => {
  msgList.value.unshift(newdata);
};

export const saveNewWelcomeMsg = async (data) => {
  try {
    loading.value = true;
    await requestUpdateWelcomeMessage(data);
  } finally {
    loading.value = false;
  }
};

export const toggleSplashActive = async (data) => {
  try {
    loading.value = true;
    await requestFlagWelcomeMessage(data);
  } finally {
    loading.value = false;
  }
};
